import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Subscribe from '../components/Subscribe';
import Header from '../components/Header';

import demoImage1 from '../assets/images/demo-image-01.jpg';
import demoImage2 from '../assets/images/demo-image-02.jpg';

const IndexPage = () => (
  <Layout>
    <Header />
    <header className="masthead">
      <div className="container d-flex h-100 align-items-center">
        <div className="mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">{config.heading}</h1>
          <h2 className="text-white-50 mx-auto mt-2 mb-5">
            {config.subHeading}
          </h2>
          <Scroll type="id" element="projects">
            <button className="btn btn-primary" href='#'>
              Le canyon marin, c'est quoi ?
            </button>
          </Scroll>
        </div>
      </div>
    </header>

    <section id="projects" className="projects-section bg-light">
      <div className="container">
        <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
          <div className="col-lg-6">
            <img className="img-fluid" src={demoImage1} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-left">
                  <h6 className="mb-0 text-white-50">Le canyon marin, c’est quoi ?</h6>
                  <hr className="d-none d-lg-block mb-0 ml-0" />
                  <br></br>
                  <p className="mb-0 text-white-50">
                  Le canyon Marin est une nouvelle activité.
Elle est apparue en tant que loisir encadré dès les années 1990 au Pays de Galles, puis s’est développée en Angleterre et en Irlande.
Depuis quelques années, elle se développe en France.
Avec notre site près de Calvi, nous avons été les premiers à encadrer cette activité en Corse.
Nous sommes basés à coté de Calvi et le spot de Canyon Marin est situé à 10min de la ville dans en endroit très sauvage.

                  </p>
                 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters">
          <div className="col-lg-6">
            <img className="img-fluid" src={demoImage2} alt="" />
          </div>
          <div className="col-lg-6 order-lg-first">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-right">
                
                  <p className="mb-0 text-white-50">
                  
Le coasteering (canyon marin) associe plusieurs activités telles que la marche, l’escalade, le rappel, la tyrolienne (60m) et les sauts (de toutes les hauteurs).
Vous allez pouvoir vous initier à toutes ses activités et même vous perfectionner, puisque vous pourrez les faire chacune plusieurs fois.
Vous serez encadré par un guide de canyoning spécialiste du canyon marin, qui saura vous initier à cette nouvelle pratique en toute sécurité.


                      </p>
                  <hr className="d-none d-lg-block mb-0 mr-0" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center no-gutters">
         <hr/>
              <h4 className="bg-black text-center h-100 project">
              Toute l’équipe de Balagne Montagne Aventure vous attend pour tester cette nouvelle activité que vous allez adorer !<br></br>
                Plus d'informations sur notre site : <a href={config.externalSite}>https://canyoningbalagne.fr/</a></h4>
           
         
        </div>
      </div>
    </section>

    <Subscribe />

    <SocialLinks />
    <Footer />
  </Layout>
);

export default IndexPage;
